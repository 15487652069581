.content {
  padding: 12px;
}

.coloring {
  color: black;
}

.navbar-brand {
  margin-left: 10px;
}

.nav-link {
  margin-left: 10px;
}
.navbar-toggler {
  margin-right: 10px;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  color: #ff1100;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fbar a {
  line-height: 1em;
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  color: #999da2;
}
h2{
  text-decoration: underline;
  color:#8446a2
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
